import './App.css';
import Routes from './router/Routes';

function App() {
  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
